@import '../../css-variables.scss';

.marketing-desktop-container {
  // margin-top: 25vh;
  display: flex;
  flex-direction: row;
  // background-color: blue;
  margin: 5vh 10vw 0 10vw;
  padding: 5vh 0 5vh 0;
  border-top: 1px solid $primaryBlue;
  
  &.reversed {
    flex-direction: row-reverse;
  }
  
  .text-side {
    flex: 0.75;
    align-self: center;
    
    .cta {
      font-family: GoboldExtra1;
      color: black;
      font-size: 2.5vh;
    }

    .tagline {
      margin-top: 5px;
      color: $primaryBlue;
      font-size: 2vh;
    }

    .buttons-section {
      display: flex;
      flex-direction: row;

      div {
        margin-left: 30px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .image-side {
    flex: 0.25;
    height: 100%;

    .main-img {
      height: 30vh;
    }
  }
}