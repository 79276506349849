body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-card-actions > li > span {
  height: 100% !important;
}

.ant-card-actions > li > span > button {
  padding: 15px;
}

.ant-card-actions > li > span > button.ready {
  background: #caf3cabf;
  border-width: 2px;
  border-style: solid;
}

.ant-card-actions > li {
  width: 50%;
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: GoboldExtra1;
  src: url('./assets/fonts/Gobold/Gobold\ Extra1.otf');
  /* font-weight: 100; */
  font-style: normal;
}

@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold/Gobold\ Extra1\ Italic.otf');
  /* font-weight: 100; */
  font-style: italic;
}
/* 
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold/Gobold\ CUTS.otf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold/Gobold\ CUTS\ Italic.otf');
  font-weight: 200;
  font-style: italic;
} */
/* 
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold/Gobold\ Extra1.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold/Gobold\ Extra1\ Italic.otf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold/Gobold\ Extra2.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold/Gobold\ Extra2\ Italic.otf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold/');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Gobold;
  src: url('./assets/fonts/Gobold');
  font-weight: 100;
  font-style: normal;
} */
