@import '../css-variables.scss';

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10vh;

  .image-section {
    height: 100%;

    .image {
      height: 7vh;
    }
  }

  .button {
    font-family: GoboldExtra1;
    color: black;
    margin-left: 10px;
    font-size: 2.5vh;
    border: 2px solid $primaryRed;
    border-radius: 100px;
    padding: 3px 13px;
    cursor: pointer;
    text-align: center;
    // height: 5vh;
  }
}