@import '../css-variables.scss';

.office-jobs-list-container {
  height: 100%;

  .no-jobs {
    width: 100%;
    height: 100%;
    background-color: lighten(lightgray, 10%);
    opacity: 0.9;
    font-size: 4vh;
    font-family: GoboldExtra1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primaryRed;
  }

  .fab {
    background-color: $primaryBlue;
    position: absolute;
    right: 4vw;
    bottom: 4vh;
    // color: $primaryRed;

    &:hover {
      background-color: darken($primaryBlue, 20%);
    }
  }

  .jobs-list {
    margin: 0 2vw;

    .job-list-text {
      font-family: GoboldExtra1;
      font-size: 3vh;
      margin: 0 !important;
      padding: 2vh 0;
    }

    // .ant-list-bordered {
    //   border-color: $primaryBlue;
    //   border-width: 2px;
    // }

    .each-job {
      cursor: pointer;
      color: black;
      display: flex;
      flex-direction: row;
      transition: 1s;
      &.active {
        background-color: lighten($primaryBlue, 30%);
      }
      &.finished {
        background-color: lighten($primaryRed, 30%);
      }

      .job-id {
        color: gray;
      }



      &:hover .buttons-section {
        opacity: 1;
      }

      .buttons-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 0;
        transition: 0.5s;
        // height: 0;
        opacity: 0;
        .current-state {
          margin: 0 1vw;
        }

        .next-state {
          &.not-started {
            background-color: lighten($primaryBlue, 10%);
            border-color: lighten(black, 50%);;
          }

          &.active {
            background-color: lighten($primaryRed, 30%);
            border-color: lighten(black, 50%);;
          }

          &.finished {
            background-color: lighten($primaryBlue, 10%);
            border-color: lighten(black, 50%);
          }
          
        }

        
      }
    }
  }
}