@import '../../css-variables.scss';

.craft-category-page-container {

  .list-header {
    font-family: GoboldExtra1;
    font-size: 3vh;
  }

  .each-item {
    margin: 0 3vw;
    padding: 2vh 3vw;
    border-bottom: 1px solid lightgray;

    &:last-child {
      border-bottom: none;
    }
  }
}