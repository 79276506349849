@import '../css-variables.scss';


.login-section-comp {
  width: 100%;
  height: 100%;
  position: relative;
  transition: 1s;

  .background-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .body {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    .modal-header-section {
      font-family: GoboldExtra1;
      font-size: 3vh;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .logo {
        height: 4vh;
      }
    }

    .error {
      color: $primaryRed;
      margin-top: 1vh;
    }

    .button-custom-class {
      background-color: $primaryBlue;
      font-family: GoboldExtra1;
      width: 100%;
      margin-top: 3vh;
      font-size: 2vh;
      height: 5vh;
      &:hover {
        background-color: darken($primaryBlue, 20%);
      }
  
      &.mobile {
        height: 10vw;
        font-size: 4vw;
      }
    }

    .signup-section {
      display: flex;
      width: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 2vh;
      flex-direction: column;
      // flex-wrap: nowrap;
      
      .signup {
        margin-left: 5px;
        width: auto;
      }
    }
  }
}
