@import '../../css-variables.scss';

.error-section {
  color: $primaryRed;
  font-size: 20px;
}

.success-header {
  font-size: 30px;
  font-family: GoboldExtra1;
  text-align: center;
  color: black;
}

.success-body {
  font-size: 20px;
  text-align: center;
}

.main-cta-desktop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2vw;
  margin-bottom: 17vh;

  .camera-section {
    height: 100%;

    .camera-image {
      height: 23vh;
    }
  }

  .main-cta {
    font-family: GoboldExtra1;
    font-size: 4vh;
    color: black;
    text-align: center;
    margin-top: 4vh;
  }

  .tagline {
    text-align: center;
    color: $primaryBlue;
    font-size: 2.5vh;
    margin-top: 3vh;
  }

  .cta-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8vh;

    .phone-section {
      height: 100%;

      .phone-image {
        height: 7vh;
      }
    }

    .cta-button {
      font-family: GoboldExtra1;
      color: black;
      margin-left: 10px;
      font-size: 2.5vh;
      border: 2px solid $primaryRed;
      border-radius: 100px;
      padding: 3px 13px;
      cursor: pointer;
    }
  }
}