@import '../../css-variables.scss';

.footer-desktop-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5vh;
  // background-color: blue;

  .line {
    width: 100%;
    height: 3vh;
    background-color: $primaryRed;
  }

  .content {
    background-color: $primaryBlue;
    font-family: GoboldExtra1;
    display: flex;
    flex-direction: row;
    padding: 3vh 10vw;

    .each-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:last-child {
        align-items: flex-end;
      }

      .each-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        padding-bottom: 3vh;
        cursor: pointer;

        &:last-child {
          padding-bottom: 0;
        }

        .image-section {
          background-color: white;
          border-radius: 500px;
          margin-right: 15px;

          .image {
            height: 7vh;
          }
        }

        .text {
          font-size: 2vh;
        }
      }

      .text-container {
        color: black;

        .contact-us-text {
          font-size: 2.5vh;
          margin-bottom: 3vh;
        }

        .each-contact-link {
          font-size: 1.7vh;
          padding-bottom: 0.5vh;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }

    
  }
  .social-media-section {
    background-color: $primaryBlue;
    display: flex;
    justify-content: center;

    .each-social-media {
      .each-sm-icon {
        color: white;
        margin-right: 30px;
        cursor: pointer;
        margin-bottom: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}