@import '../../css-variables.scss';

.find-job-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .container {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // height: 100%;
    height: 50vh;
    width: 70vw;
    border: 1px solid black;
    border-radius: 5px;
    z-index: 1;
  }

  .background-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .find-job-text {
    z-index: 1;
    font-family: GoboldExtra1;
    font-size: 3vh;
    margin-bottom: 1vh;
  }

  .input-sections {
    .input {
      // background-color: white;
      color: white !important;
    }
  }

  .submit-button {
    margin-top: 2vh;
    background-color: $primaryBlue;

    &:hover {
      background-color: darken($primaryBlue, 20%);
    }
  }

  .error-section {
    margin-top: 1vh;
    color: $primaryRed;
  }
}