@import '../css-variables.scss';

.craft-office-job-menu-subs-container {

  // .each-panel {
  //   &:hover {
  //     .right-side {
  //       .printable-button {
  //         display: inline;
  //       }
  //     }
  //   }
  // }

  .right-side {
    display: flex;
    flex-direction: row;
    align-items: center;

    

    // .printable-button {
    //   display: none;
    // }
  }
  // background-color: blue;
  .each-day-item.add-sub-to-day {
    // padding-top: 2vh !important;
    cursor: pointer;
    &:hover {
      background-color: $primaryBlue;
    }
    padding-bottom: 2vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: GoboldExtra1;
    font-size: 3vh;
  }
  .each-day-item {
    color: black;
    // background-color: blue;
    padding: 2vh 0;
    border-bottom: 1px solid lightgray;
    // cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actions-section {
      opacity: 0;
    }

    .disabled {
      color: $primaryRed;
    }

    &:hover .actions-section {
      opacity: 1;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    
    .edit-icon {
      display: none;
    }

    &:hover .edit-icon {
      display: inline;
    }

    .desc {
      color: gray;
    }

  }

  .view-meal-orders {
    padding: 0 2vw;
    color: #3f51b5;
    font-weight: 600;
  }
}