@import '../../css-variables.scss'; 

.navbar-desktop-container {
  width: 100%;
  position: fixed;
  background-color: white;
  transition: 1s;
  display: flex;
  flex-direction: row;
  padding: 0 5vw;
  z-index: 10;

  &.isTop {
    height: 25vh;
  }

  &.isScrolling {
    height: 10vh;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
  
  .logo-section {
    height: 100%;

    .logo {
      padding: 10px 0;
      transition: 1s;

      &.isTop {
        height: 25vh
      }

      &.isScrolling {
        height: 10vh
      }
    }
  }

  .login-side {
    // flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: black;

    .login-icon-section {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      
      .login {
        margin-right: -20%;
        transition: 1s;

        &.isTop {
          height: 20vh;
        }

        &.isScrolling {
          height: 12vh;
        }
      }
    }

    .login-button {
      transition: 1s;
      font-family: GoboldExtra1;
      border-bottom-style: solid;
      border-bottom-color: $primaryRed;

      &.isTop {
        font-size: 6vh;
        border-bottom-width: 0.7vh;
      }
  
      &.isScrolling {
        font-size: 2.5vh;
        border-bottom-width: 0.2vh;
      }

    }
  }

  .tabs-section {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;

    .each-tab {
      margin-left: 2vw;
      font-family: GoboldExtra1;
      color: $primaryBlue;
      font-size: 3vh;
    }

    .active {
      color: darken($primaryBlue, 40%)
    }

  }
}
