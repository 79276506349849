@import '../../css-variables.scss';

.home-page-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 10vw;

  .each-button {
    margin: 3vh 0;
    height: 8vh;
    border-color: $primaryBlue;
    // font-family: GoboldExtra1;
    font-size: 3vh;
    &.disabled {
      color: lightgray;
      border-color: lightgray;
      background: #fcfcfc;
    }

    &.lunch-reminder {
      height: 100px;
      // background-color: #fdf3a9;
    }
  }
}