@import '../css-variables.scss';

.craft-office-job-meals-container {

  .each-meal {
    padding: 2vh 3vw;
    border-bottom: 1px solid lightgray;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }

    .meal-name {
      font-size: 3vh;
    }

    .options-section {
      margin-left: 2vw;

      .option-item-section {
        margin-left: 2vw;
      }
    }

    .username-section {
      width: 15vw;
    }

  }

  .complete-subs-button {
    width: 30%;
    margin: 2vh 35%;
    background-color: $primaryBlue;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}