@import '../../css-variables.scss'; 

.navbar-mobile-container {
  width: 100%;
  position: fixed;
  background-color: white;
  transition: 1s;
  display: flex;
  flex-direction: row;

  &.isTop {
    height: 15vh;
  }

  &.isScrolling {
    height: 10vh;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }

  .logo-section {
    height: 100%;

    .logo {
      padding: 10px 20px;
      transition: 1s;

      &.isTop {
        height: 15vh;
      }
  
      &.isScrolling {
        height: 10vh
      }
    }
  }

  .login-side {
    flex: 1;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5vw;
    color: black;

    .login-icon-section {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      
      .login {
        // height: 70%;
        margin-right: -20%;
        transition: 1s;

        &.isTop {
          height: 15vh;
        }

        &.isScrolling {
          height: 9vh;
        }
      }
    }

    .login-button {
      transition: 1s;
      font-family: GoboldExtra1;
      border-bottom-style: solid;
      border-bottom-color: $primaryRed;

      &.isTop {
        font-size: 4vh;
        border-bottom-width: 0.7vh;
      }
  
      &.isScrolling {
        font-size: 2.5vh;
        border-bottom-width: 0.2vh;
      }
      
    }
  }
}
