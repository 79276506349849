@import '../css-variables.scss';

.craft-on-site-job-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
      padding-right: 30px;
    }
  }

  .item-sec {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .left {
      flex: 0.7
    }

    .right {
      flex: 0.3;
      display: flex;
      justify-content: flex-end;

      .each-button {
        white-space: nowrap;
        margin-left: 10px;

        &:last-child {
          margin-right: 10px;
        }
      }
      
    }
  }

  .no-orders-section {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: GoboldExtra1;
    font-size: 2vh;
  }

  .orders {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 2vh;
    height: fit-content;

    .title {
      margin-bottom: 1.5vh;
      display: flex;
      justify-content: space-between;
    }
  
    .items-section {
      // background-color: blue;
      color: black;
      
      .item-name {
        // font-size: 1.5vh;
        font-weight: bold;
      }
  
      .item-options {
        margin-left: 1.5vw;
  
        .option-item-label {
          margin-left: 1.5vw;
        }
      }
    }
  }

  .divider-text {
    font-family: GoboldExtra1;
    font-size: 3vh;
  }

  .right-side {
    width: 100%;
    height: 100%;
    // position: relative;
    // height: calc(100%  - 10vh);
    
  
    
  
    .each-craft-item {
      display: flex;
      padding: 1vh 2vw;
      margin: 0 1vw;
      align-items: center;
      border-bottom: 1px solid lightgray;
  
      &:last-child {
        border-bottom: none;
      }
  
      .button {
        margin-left: 2vw;
      }
  
  
    }
  
    .meals-section {
  
      .each-meal {
        padding: 2vh 3vw;
        border-bottom: 1px solid lightgray;
        color: black;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
  
        &:last-child {
          border-bottom: none;
        }
  
        .meal-name {
          font-size: 3vh;
        }
  
        .options-section {
          margin-left: 2vw;
  
          .option-item-section {
            margin-left: 2vw;
          }
        }

        .username-section {
          width: 15vw;
        }

      }
  
      .complete-subs-button {
        width: 30%;
        margin: 2vh 35%;
        background-color: $primaryBlue;
      }
    }

    .day-changer {
      display: flex;
      font-size: 20px;
      height: 40px;
      
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      .day-section {
        background-color: #36c6f4;
        justify-content: center;
        align-items: center;
        flex: 1;
      }

      .fake {
        width: 20vw;
        min-width: 340px;
      }
    }
  }

  .fake-left-side {
    width: 20vw;
    // height: calc(100% - 10vh);
    min-width: 340px;
    // overflow: auto;
    // border-left: 5px solid $primaryRed;
    // position: fixed;
    // right: 0;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .left-side {
    width: 20vw;
    height: calc(100% - 10vh);
    min-width: 340px;
    overflow: auto;
    border-left: 5px solid $primaryRed;
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
  }


  
}