.privacy-policy-container {
  width: 100%;
  overflow: hidden;

  .body {
    margin: 15vh 10vw;
    color: black;

    .title {
      width: 100%;
      text-align: center;
      font-family: GoboldExtra1;
      font-size: 5vh;
      margin-bottom: 3vh;
    }

    .header {
      font-weight: bold;
      margin-top: 2vh;
      margin-bottom: 1vh;
      font-size: 3vh;
    }

    .paragraph {
      font-size: 2vh;
      margin-bottom: 0.8vh;
      margin-top: 0.8vh;

      &.one-indent {
        margin-left: 3vw;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .subheader {
      font-weight: bold;
      margin-top: 1vh;
      margin-bottom: 1vh;
      font-size: 2.5vh;
    }

    
  }
}