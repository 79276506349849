@import '../css-variables.scss';

.craft-office-manage-users-container {

  .divider-text {
    font-family: GoboldExtra1;
    font-size: 3vh;
    margin: 0 !important;
    padding-top: 2vh;
  }
  
  .fab {
    background-color: $primaryBlue;
    position: absolute;
    right: 4vw;
    bottom: 4vh;
    // color: $primaryRed;

    &:hover {
      background-color: darken($primaryBlue, 20%);
    }

    
  }

  .error {
    color: $primaryRed
  }

  .each-user {
    margin: 0 2vw;
    padding: 1.5vh 1vw;
    color: black;
    border-bottom: 1px solid lightgray;

    &:last-child {
      border-bottom: none;
    }

    .user-role {
      color: gray;
    }
  }
}