@import '../css-variables.scss';


.signup-section-comp {
  width: 100%;
  height: 100%;
  position: relative;
  transition: 1s;

  .background-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .body {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    .modal-header-section {
      font-family: GoboldExtra1;
      font-size: 3vh;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .logo {
        height: 4vh;
      }
    }

    .error {
      color: $primaryRed;
      margin-top: 1vh;
    }

    .button-custom-class {
      background-color: $primaryBlue;
      font-family: GoboldExtra1;
      width: 100%;
      margin-top: 3vh;
      font-size: 2vh;
      height: 5vh;
      &:hover {
        background-color: darken($primaryBlue, 20%);
      }
  
      &.mobile {
        height: 10vw;
        font-size: 4vw;
      }
    }

    .login-section {
      display: flex;
      width: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 2vh;
      flex-direction: column;
      // flex-wrap: nowrap;
      
      .login {
        margin-left: 5px;
        width: auto;
      }
    }
  }
}






// .signup {
//   /* background-color: lightpink; */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   flex-direction: column;
// }

// .signup > .headers {
//   display: flex;
//   background-color: white;
//   justify-content: center;
//   align-items: center;
//   color: black;
// }

// .signup > .headers > .text {
//   color: black;
//   font-size: 35px;
// }

// .signup > .signup-section {
//   width: 25%;
// }

// .signup > .signup-section > .each-section {
//   display: flex;
//   justify-content: space-between;
//   margin: 5px 0;
//   align-items: center;
// }

// .signup > .signup-section > .each-section > .input {
//   width: 14vw;
//   padding: 5px 10px;
//   border-color: lightgray;
//   box-shadow: none;
//   outline: none;
//   border-radius: 5px;
//   border-width: 1px;
//   outline: none;
// }

// .signup > .signup-section > .button-section {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 20px;
// }

// .signup > .signup-section > .button-section > .button {
//   padding: 5px 30px;
//   background-color: lightblue;
//   border-color: lightblue;
//   border-radius: 5px;
//   border-width: 1px;
//   color: black;
// }

// .signup > .signup-section > .error-section {
//   color: red;
//   margin-top: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .signup > .login-section {
//   margin-top: 30px;
// }