@import '../css-variables.scss';

.header-container {
  // background-color: $primaryRed;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0 4vw;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #eceaea;

  .left-side {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .logo-image {
      height: 80%;
    }
  }

  .page-title {
    font-size: 4vw;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // height: 56px;
    // width: 30vw;
  }

  .right-side {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

}
