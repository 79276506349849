@import '../../css-variables.scss';

.basket-page-container {
  .location-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 5px 10px;
    font-size: 20px;
    font-weight: bold;
    color: black;

    &.blink {
      .select-section {
        animation-name: locationSelectAnim;
        animation-duration: 1.5s;
        animation-iteration-count: 2;
      }
    }

  }

  @keyframes locationSelectAnim {
    0% { box-shadow: none; }
    50% { box-shadow: 0px 0px 10px 6px $primaryRed; }
    100% { box-shadow: none; }
  }

}