.craft-office-locations-container {
  .add-location {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-family: GoboldExtra1;
    font-size: 25px;
    cursor: pointer;
    color: #000;
  }
}