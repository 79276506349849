.caterer-job-container {
  color: black;

  .list {
    display: flex;
    flex-direction: column;
    flex: 1;

    .each-row {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      border-bottom: 1px solid gray;
      padding: 20px 30px;
      margin: 0 20px;

      .left-side {
        display: flex;
        flex-direction: row;

        .date-section {
          margin-left: 3px;
        }
      }
    }
  }
}