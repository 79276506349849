.job-craft-items-container {
  margin-bottom: 100px;

  .cat-collapse {
    transition: all 0.5s ease;
  }

  .each-panel-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .each-toggle-button {
    margin-left: 10px;
  }

  .cat-panel-header.disabled {
    color: red;
  }

  .each-option-item {
    padding: 1vh 0;
    border-bottom: 1px solid lightgray;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}