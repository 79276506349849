@import '../../css-variables.scss';

.marketing-mobile-container {
  // margin-top: 25vh;
  display: flex;
  flex-direction: column-reverse;
  // background-color: blue;
  margin: 10vh 10vw 0 10vw;

  
  
  .text-side {
    flex: 0.75;
    align-self: center;
    margin-top: 2vh;
    
    .cta {
      font-family: GoboldExtra1;
      color: black;
      font-size: 2.5vh;
      text-align: center;
    }

    .tagline {
      margin-top: 5px;
      color: $primaryBlue;
      font-size: 2vh;
      text-align: center;
    }

    .buttons-section {
      display: flex;
      flex-direction: column;

      
    }
  }

  .image-side {
    flex: 0.25;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    .main-img {
      height: 30vh;
    }
  }
}