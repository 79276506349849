.craft-office-printable-meals-container {
  margin: -10vh 3vw 2vh 3vw;
  color: black;

  .pickedup-order {
    text-decoration: line-through;
  }

  -webkit-print-color-adjust: exact;
  // page-break-before: always;
  page-break-after: avoid;
  @page {
    margin: 1.5cm;
  }

  @media print {
    .hidden-print {
      display: none;
    }

    .pickedup-order {
      text-decoration: none;
    }
  }
  // page-break-inside: avoid;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh 0;

    .logo-part {
      .logo {
        width: 100px;
      }
    }

    .job-part {
      font-family: GoboldExtra1;
      font-size: 3vh;
    }
  }

  .divider {
    font-size: 2.5vh;
    font-family: GoboldExtra1;
    margin-top: 8vh;
  }

  .meals-divider {
    margin-top: 0;
  }

  .salad-section {
    margin-bottom: 10vh;
  }

  .each-section {
    margin-top: 3vh;
    // max-width: 50vw;
    .headers {
      // background-color: blue;
      display: flex;
      flex-direction: row;
      font-weight: bold;
      font-size: 2vh;
      // background-color: blue;

      .section-name {
        flex: 0.6;
      }

      .num-orders {
        flex: 0.4;
      }
    }

    .body {
      // margin-bottom: 10vh;
      .row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid gray;
        align-items: center;
        .section-cell {
          flex: 0.6;
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
        }
        .num-orders-cell {
          flex: 0.4;
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
        }

        .notes-cell {
          flex: 0.4;
        }

        &:first-child {
          border-top: 1px solid gray;
        }

        &:nth-child(odd) {
          background-color: lightgray !important;
        }
      }
    }
  }

  .acc-meal-by-dept {
    .headers {
      display: flex;
      flex-direction: row;
      font-weight: bold;

      .each-header {
        font-size: 2vh;
      }

      .dept-header {
        flex: 0.2;
        padding: 0 4px;
      }

      .total-num-header {
        flex: 0.1;
      }

      .usernames-header {
        flex: 0.2;
      }

      .meals-header {
        flex: 0.8;
      }

      .notes-header {
        flex: 0.3;
      }
    }

    .body {
      display: flex;
      flex-direction: column;

      .each-row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid gray;

        &:nth-child(odd) {
          background-color: lightgray;
        }

        &:first-child {
          border-top: 1px solid gray;
        }

        .dept {
          flex: 0.2;
          display: flex;
          align-items: center;
          padding: 0 4px;
          max-width: 150px;
          overflow: hidden;
        }

        .num-orders {
          flex: 0.1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
        }

        .rest {
          display: flex;
          flex-direction: column;
          flex: 1.5;

          .internal-row {
            // flex: 0.8;
            display: flex;
            padding: 1.5vh 0;
            border-bottom: 1px solid black;
            border-bottom-style: dashed;

            &:last-child {
              border-bottom: none;
            }
            .names {
              // margin-left: 2px;
              flex: 0.2;
              align-items: center;
              display: flex;
            }

            .meals {
              flex: 0.8;
              align-items: center;
              // display: flex;

              .each-option {
                color: darken(gray, 20%);
                margin-left: 4px;

                .option-name {
                  font-style: italic;
                  font-weight: bold;
                }
              }
            }

            .notes {
              flex: 0.3;
              align-items: center;
              display: flex;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .specific {
    .headers {
      display: flex;
      flex-direction: row;
      font-weight: bold;

      .meals-header {
        flex: 0.5;
        font-size: 2vh;
      }
      .names-header {
        flex: 0.2;
        font-size: 2vh;
      }
      .notes-header {
        flex: 0.3;
        font-size: 2vh;
      }
    }

    .body {
      .each-row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid gray;

        .meal-cell {
          flex: 0.5;
          user-select: all;
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
        }

        .username-cell {
          flex: 0.2;
          // display: flex;
          // justify-content: center;
          // flex-direction: column;

          .each-username {
            user-select: all;
          }
        }
        .note-cell {
          flex: 0.3;
          user-select: all;
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
        }

        &:first-child {
          border-top: 1px solid gray;
        }

        .options {
          // display: flex;
          // flex-direction: row;
          margin-left: 10px;

          .option-name {
            font-style: italic;
          }

          .option-items {
            margin-left: 10px;
          }
        }

        &:nth-child(odd) {
          background-color: lightgray;
        }
      }
    }
  }
}

/* Pickup Times Specific */
.acc-meal-by-dept-pickupTimes {
  .headers-pickupTimes {
    display: flex;
    flex-direction: row;
    font-weight: bold;

    .total-num-header-pickupTimes {
      flex: 0.1;
    }

    .each-header-pickupTimes {
      font-size: 2vh;
    }

    .send-text-header-pickupTimes {
      flex: 0.1;
    }

    .dept-header-pickupTimes {
      flex: 0.1;
    }

    .usernames-header-pickupTimes {
      flex: 0.1;
    }

    .meals-header-pickupTimes {
      flex: 0.4;
    }

    .pickup-time-header-pickupTimes {
      flex: 0.1;
    }

    .notes-header-pickupTimes {
      flex: 0.2;
    }
  }

  .rest-headers-pickupTimes {
    display: flex;
    flex: 0.9;
  }

  .body-pickupTimes {
    display: flex;
    flex-direction: column;

    .each-row-pickupTimes {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid gray;
      justify-content: space-around;

      &:nth-child(odd) {
        background-color: lightgray;
      }

      &:first-child {
        border-top: 1px solid gray;
      }

      .num-orders-pickupTimes {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        flex: 0.1;
      }

      .rest-pickupTimes {
        display: flex;
        flex-direction: column;
        flex: 0.9;

        .internal-row-pickupTimes {
          // flex: 0.8;
          display: flex;
          padding: 1.5vh 0;
          border-bottom: 1px solid black;
          border-bottom-style: dashed;

          &:last-child {
            border-bottom: none;
          }

          .send-text-pickupTimes {
            display: flex;
            align-items: center;
            flex: 0.1;
          }

          .dept-pickupTimes {
            display: flex;
            align-items: center;
            flex: 0.1;
          }

          .names-pickupTimes {
            // margin-left: 2px;
            align-items: center;
            display: flex;
            flex: 0.1;
            flex-wrap: wrap;
            margin-right: 20px;
          }

          .meals-pickupTimes {
            align-items: center;
            display: flex;
            flex: 0.4;
            margin-left: 20px;

            .each-option-pickupTimes {
              color: darken(gray, 20%);
              margin-left: 4px;

              .option-name-pickupTimes {
                font-style: italic;
                font-weight: bold;
              }
            }
          }

          .pickup-time-pickupTimes {
            align-items: center;
            flex: 0.1;
          }

          .notes-pickupTimes {
            justify-content: center;
            align-items: center;
            display: flex;
            font-weight: bold;
            flex: 0.2;
          }
        }
      }
    }
  }
}
