@import '../css-variables.scss';

.global-menu-subs-container {
  
}
.refactor-me {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.full-width {
  width: 100%;
}

.modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .modal-section {
    margin: 1vh 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-top: 0;
    }
  }

  .modal-error {
    color: $primaryRed;
    margin-top: 1vh;
  }


}