@import '../css-variables.scss';

.global-menu-container {


  .each-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }






  .body {
    // margin: 0 2vw;
    // .panel-header {
    //   font-family: GoboldExtra1;
    //   font-size: 2vh;
    // }

    .extra-side {
      .add-option-icon {
        margin-right: 1vw;
      }

      .add-option-icon:hover {
        border-radius: 100px;
        background-color: lightgray;
      }

      .add-icon:hover {
        border-radius: 100px;
        background-color: lightgray;
      }
    }

    .each-sub-item {
      color: black;
      padding: 2vh 0;
      border-bottom: 1px solid lightgray;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      
      .edit-icon {
        display: none;
        cursor: pointer;
        
        &:hover {
          background-color: lightgray;
          border-radius: 100px;
        }
      }

      &:hover .edit-icon {
        display: inline;
      }

      .desc {
        color: gray;
      }

    }
    
  }


  .each-option-item {
    width: 100%;
    padding: 1vh 1vw;
    border-bottom: 1px solid lightgray;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    &:first-child {
      padding-top: 0;
    }
  }
}

