.landing-page-container {
  // font-family: GoboldExtra1;
  // background-color: blue;
  width: 100%;
  overflow: hidden;
  transition: 1s;

  .body {
    // overflow-y: auto;
    height: 100%;
    transition: 1s;
  }
}

